import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Container from '../components/Container'

class SignUp extends React.Component {
  componentWillMount() {}

  componentWillUnmount() {}

  errors = {}

  iframe() {
    return {
      __html: `<iframe frameborder="0" style="height:3800px;width:99%;border:none;" src='
      https://forms.embello.com/tijana/form/NewSignUpInfluencerForm/formperma/IzW5Ke37g1K4DaKB4AITmWKz0D8c_jLWiXGFGmvNnpU'></iframe>`,
    }
  }

  zohoJS() {
    return {
      __html: `<script type="text/javascript" src="https://forms.embello.com/tijana/form/NewSignUpInfluencerForm/jsperma/IzW5Ke37g1K4DaKB4AITmWKz0D8c_jLWiXGFGmvNnpU" id="ZFScript"></script>`,
    }
  }

  zohoPopup() {
    return {
      __html: `<script type="text/javascript">function zforms_open_window(url, height, width){var leftPos = 0;var topPos = 0;if(screen){leftPos = (screen.width - width) / 2;topPos = (screen.height - height) / 2;window.open(url, null, 'width='+width+',height='+height+',left='+leftPos+',top='+topPos+', toolbar=0, location=0, status=1, scrollbars=1, resizable=1');}}</script><a href='https://forms.embello.com/tijana/form/NewSignUpInfluencerForm/formperma/IzW5Ke37g1K4DaKB4AITmWKz0D8c_jLWiXGFGmvNnpU' title="New Sign Up Influencer Form" target='_blank' onclick="zforms_open_window(this.href, 648, 700); return false">Access Form</a>`,
    }
  }

  render() {
    return (
      <Layout whiteNav>
        <Helmet>
          <div dangerouslySetInnerHTML={this.zohoJS()} />
          <div dangerouslySetInnerHTML={this.zohoPopup()} />
        </Helmet>

        <Container padding="109px 0 0" maxWidth='100%'>
          <div dangerouslySetInnerHTML={this.iframe()} />
        </Container>
      </Layout>
    )
  }
}

export default SignUp
